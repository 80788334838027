<script setup lang="ts">
import { getCurrentInstance, type PropType } from "vue";
import { register } from "swiper/element/bundle";
import { Navigation } from "swiper/modules";
import type { ISwiperBreakpoints } from "~/composable/swiper.type";

const props = defineProps({
  showNavs: {
    type: Boolean,
    default: true,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
  breakpoints: {
    type: Object as PropType<ISwiperBreakpoints>,
    default: () => ({
      0: {
        slidesPerView: 1,
        spaceBetween: 12,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      960: {
        slidesPerView: 3,
        spaceBetween: 12,
      },

      1280: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    }),
  },
});

register();

const component = getCurrentInstance();
const slider = ref();
</script>

<template>
  <div>
    <client-only>
      <swiper-container
        ref="slider"
        :modules="[Navigation]"
        :slides-per-view="4"
        :space-between="20"
        :pagination="true"
        :navigation="{
          prevEl: `.controls-prev-${component.uid}`,
          nextEl: `.controls-next-${component.uid}`,
        }"
        :breakpoints="breakpoints"
      >
        <slot name="default"></slot>
        <div slot="container-start">
          <div class="eqzaim-slider__title" v-show="showTitle">
            <slot name="title"></slot>
            <div class="eqzaim-slider__controls" v-show="props.showNavs">
              <button
                :class="`controls-prev-${component.uid}`"
                class="eqzaim-slider__controls-prev"
                ref="prev"
              >
                <LazyIconsEqZaimIcon icon-name="arrow-left2" />
              </button>
              <button
                :class="`controls-next-${component.uid}`"
                class="eqzaim-slider__controls-next"
                ref="next"
              >
                <LazyIconsEqZaimIcon icon-name="arrow-right2" />
              </button>
            </div>
          </div>
        </div>
      </swiper-container>
    </client-only>
  </div>
</template>
<style scoped lang="postcss">
.eqzaim-slider {
  &__title {
    @apply flex justify-between mb-[44px];
  }
  &__controls {
    @apply gap-[32px] items-end hidden xl:flex;
    :deep(.swiper-button-disabled) {
      @apply text-secondary-brown;
    }

    &-prev {
      @apply text-primary-black rounded-3xl bg-secondary-gray-50 w-[44px] h-[44px] flex items-center justify-center;
    }

    &-next {
      @apply text-primary-black rounded-3xl bg-secondary-gray-50 w-[44px] h-[44px] flex items-center justify-center;
    }
  }
}
</style>
<style module lang="postcss">
swiper-container::part(container) {
  margin-left: 0;
  overflow: visible;
  @media (max-width: 1024px) {
    width: 90%;
    padding-bottom: 28px;
  }
}
swiper-container::part(bullet) {
  background-color: #edf1f3;
  opacity: 1;
  width: 8px;
  height: 8px;
  position: relative;
  transition: all ease-in-out 300ms;
}
swiper-container::part(bullet)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 4px;
  background-color: #2c2f2f;
  z-index: 1;
  transition: all ease-in-out 300ms;
}
swiper-container::part(bullet-active) {
  background-color: #edf1f3;
  position: relative;
  width: 44px;
  border-radius: 4px;
  transition: all ease-in-out 300ms;
}
swiper-container::part(pagination) {
  bottom: 0;
  @media (min-width: 1024px) {
    display: none;
  }
  @media (min-width: 1280px) {
    overflow-y: hidden;
  }
}
swiper-container::part(bullet-active)::before {
  background-color: #2c2f2f;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-radius: 4px;
  z-index: 1;
  transition: all ease-in-out 300ms;
}
</style>
